.blur {
	filter: blur(8px);
}
.navbar {
	font-family: NeueHaasDisplayLight, Arial;
	height: 15vh;
	max-height: 150px;
	min-height: 50px;
	display: flex;
	letter-spacing: 0.03em;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	position: fixed;
	top: 0;
	width: 100%;
	color: #fff;
	transition: top 0.3s;
	z-index: 1000;
}
.logo {
	height: 80%;
	padding-left: 3vw;
	cursor: pointer;
}
.nav-links {
	display: flex;
	gap: 5vw;
	padding-right: 70px;
	font-size: 1.5em;
}
.nav--link {
	color: #3a81b9;
	text-decoration: none;
	position: relative;
	font-size: 1em;
	font-weight: 100;
}
.nav--link.active {
	font-weight: 900;
}
.nav--link.active::after {
	content: "";
	display: block;
	position: absolute;
	bottom: -2 px;
	left: 0;
	width: 100%;
	border-bottom: 0.18rem solid #58b030;
}
.hamburger-menu {
	display: none;
	flex-direction: column;
	cursor: pointer;
	margin-right: 20px;
}
.bar {
	width: 30px;
	height: 3px;
	background-color: #3a81b9;
	margin: 2.4px 0;
	transition: 0.4s;
}
.active .bar:first-child {
	transform: rotate(-45deg) translate(-5px, 6px);
	background-color: #fff;
}
.active .bar:nth-child(2) {
	opacity: 0;
}
.active .bar:nth-child(3) {
	background-color: #fff;
	transform: rotate(45deg) translate(-5px, -6px);
}
@media screen and (max-width: 768px) {
	.nav-links {
		display: none;
	}
	.nav-links.active {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		background-color: rgba(0, 0, 0, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		padding-top: 30vh;
		gap: 40px;
		font-size: 2.5em;
	}
	.hamburger-menu {
		display: flex;
		padding-right: 5vw;
	}
	.logo {
		max-width: 70%;
		max-height: 70%;
	}
	.navbar {
		padding-left: 10px;
		height: 12vh;
	}
	.nav--link {
		color: #fff;
		width: fit-content;
	}
}
@media (min-width: 769px) and (max-width: 1544px) {
	.logo {
		max-width: 60%;
		max-height: 60%;
	}
	.nav--link {
		font-size: 0.9em;
	}
}
.navbar.active {
	transition: background-color 0.3s ease-in-out;
	opacity: 0;
	animation: 0.3s ease-in-out forwards fadeIn;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
