.title {
	text-align: center;
	font-family: NeueHaasDisplayThin, Arial, Helvetica, sans-serif;
	color: #1470af;
	font-size: 5em;
	letter-spacing: 0.03em;
	padding-bottom: 20px;
}
.heading-box {
	color: #1470af;
	border: 0.18rem solid #1470af;
	border-radius: 1rem;
	font-size: 3.5em;
	padding: 20px;
}

.about-us-big-column-text,
.about-us-column-text,
.banner-two-text,
.heading-box {
	font-family: NeueHaasDisplayRoman, Arial, Helvetica, sans-serif;
}
.banner-one {
	width: 100%;
	display: block;
	overflow: auto;
	padding-bottom: 6vh;
}
.banner-one-text {
	width: 95%;
	padding-top: 4vh;
	padding-left: 5vw;
	text-align: center;
}
.banner-two {
	width: 100%;
	display: block;
	overflow: auto;
}
.banner-two-text {
	font-size: 1.8em;
	padding: 4vh;
	border: 0.18rem solid #1470af;
	border-radius: 1rem;
}
.about-us-columns {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 70px;
}
.column {
	padding-left: 30px;
	padding-right: 30px;
}
.about-us-big-column-text {
	color: #1470af;
	font-size: 3em;
}
.about-us-column-text {
	padding-top: 40px;
	font-size: 1.5em;
}
.banner-two-text-parent {
	padding: 25px 25px 25px 25px;
}
@media (min-width: 769px) and (max-width: 1544px) {
	.title {
		font-size: 4em;
	}

	.column {
		padding-left: 10px;
		padding-right: 10px;
	}
	.heading-box {
		font-size: 2.5em;
	}
	.about-us-big-column-text {
		font-size: 2em;
	}
	.banner-two-text {
		width: 96%;
	}
}
@media (max-width: 768px) {
	.title {
		font-size: 4em;
	}
	.heading-box {
		font-size: 2em;
		padding: 20px;
	}
	.about-us-columns {
		padding-top: 20px;
	}
	.column {
		padding-left: 10px;
		padding-right: 10px;
	}
	.about-us-big-column-text {
		font-family: NeueHaasDisplayRoman, Arial, Helvetica, sans-serif;
		color: #1470af;
		font-size: 2em;
	}
	.about-us-column-text {
		padding-top: 30px;
		font-size: 1.5em;
	}
	.banner-two-text {
		width: 100%;
		font-size: 1.5em;
		padding-left: 4vw;
	}
}
