*,
body {
	margin: 0;
	padding: 0;
}
img {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}
* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}
div {
	cursor: default;
}
@font-face {
	font-family: NeueHaasDisplayBlack;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayBlack.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayBlackItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayBlackItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayBold;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayBold.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayBoldItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayBoldItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayLight;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayLight.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayLightItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayLightItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayMedium;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayMediu.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayMediumItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayMediumItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayRoman;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayRoman.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayRomanItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayRomanItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayThin;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayThin.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayThinItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayThinItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayXThin;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayXThin.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayXThinItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayXThinItalic.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayXXThin;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayXXThin.ttf")
		format("truetype");
	font-display: swap;
}
@font-face {
	font-family: NeueHaasDisplayXXThinItalic;
	src: url("./fonts/neue-haas-grotesk-display-pro/NeueHaasDisplayXXThinItalic.ttf")
		format("truetype");
	font-display: swap;
}
.top-gap {
	margin-top: 12vh;
}
/* .content {
	animation: 0.25s transitionIn;
}
@keyframes transitionIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
nav {
	animation: 0.5s transitionInNav;
}
@keyframes transitionInNav {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
} */
