#footer {
	background-color: #000;
	color: #fff;
	text-align: center;
	padding-top: 1.2%;
	padding-bottom: 1%;
}
.column {
	width: 33%;
	padding: 0;
}
.contact-heading,
.hours-heading,
.location-heading {
	padding-top: 20px;
	font-family: NeueHaasDisplayLight, Arial, Helvetica, sans-serif;
	color: #1470af;
	font-size: 1.5em;
}
.hours-timings,
.location-body {
	font-family: NeueHaasDisplayXThin, Arial, Helvetica, sans-serif;
	padding-top: 20px;
	padding-left: 5vw;
	padding-right: 5vw;
	font-size: 1em;
	letter-spacing: 0.1em;
}
.hours-timing {
	padding-bottom: 30px;
}
.contact-emails,
.contact-phone-numbers {
	font-family: NeueHaasDisplayThin, Arial, Helvetica, sans-serif;
	padding-top: 20px;
	padding-left: 0;
	padding-right: 0;
	font-size: 1em;
	letter-spacing: 0.1em;
}
.contact-email,
.contact-phone-number {
	color: #1470af;
	text-decoration: underline;
}
.copyright {
	padding: 35px 50px 10px;
	font-family: NeueHaasDisplayMedium, Arial, Helvetica, sans-serif;
	letter-spacing: 0.06em;
}
@media (max-width: 768px) {
	.column {
		width: 100%;
		padding-top: 40px;
		padding-left: 5px;
		padding-right: 5px;
	}
	.copyright {
		padding-left: 0;
		padding-right: 0;
	}
}
