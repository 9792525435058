.contact-us-title {
	font-family: NeueHaasDisplayLight, Arial, Helvetica, sans-serif;
	background-color: #1470af;
	color: #fff;
	font-size: 7em;
	letter-spacing: 0.03em;
	padding: 20px 20px 20px 50px;
}
.contact-us-content {
	padding-top: 5vh;
	width: 80vw;
	display: flex;
	flex-wrap: wrap;
	align-items: left;
	font-family: NeueHaasDisplayThin;
	font-size: 1.15em;
	padding-left: 2.5vw;
	letter-spacing: 0.05em;
	padding-bottom: 15vh;
}
.contact-us-address,
.contact-us-form {
	padding-top: 30px;
}
.contact-us-column {
	width: 33%;
	padding-right: 30px;
	padding-left: 30px;
	line-height: 1.7em;
}
.contact-us-bold {
	font-family: NeueHaasDisplayBold;
	line-height: 1em;
}
#contact-us-content {
	background-color: #eaeaee;
}
.contact-us-form {
	position: relative;
}
#contact-us-message,
.contact-us-email {
	padding-top: 15px;
}
#message {
	resize: vertical;
	height: 100px;
}
input[type="email"],
input[type="text"],
textarea {
	border: 1px solid #a9a9a9;
	border-radius: 0;
	height: 50px;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10px;
	background-color: #fafafa;
	font-family: NeueHaasDisplayThin, Arial, Helvetica, sans-serif;
	font-size: 0.9em;
	letter-spacing: 0.05em;
	padding: 10px;
}
input[type="email"]:hover,
input[type="text"]:hover,
textarea:hover {
	background-color: #f3f3f5;
}
::placeholder {
	color: #777;
}
.form-label {
	display: flex;
}
.form-required {
	padding-left: 5px;
	color: #4e4e50;
	font-size: 0.8em;
}
.contact-us-button {
	margin: 0;
	float: right;
}
.contact-us-map {
	padding-top: 20px;
}
.map {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
}
@media (max-width: 1544px) {
	.contact-us-column {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	.contact-us-content {
		width: 100%;
		padding: 40px;
	}
	.contact-us-title {
		font-size: 4em;
		padding-left: 30px;
	}
	#contact-us-button {
		margin-bottom: 50px;
	}
}
