.products-title {
	font-family: NeueHaasDisplayLight, Arial, Helvetica, sans-serif;
	color: #1470af;
	font-size: 8em;
	letter-spacing: 0.03em;
	padding: 20px 20px 20px 50px;
}
.products-container {
	width: 100%;
	padding: 7vh;
	background-color: #eaeaee;
	display: flex;
	flex-direction: column;
}
.product-row {
	display: flex;
	width: 70%;
	flex-direction: row;
	flex-wrap: wrap;
	border-top: 1px solid #000;
	padding-top: 50px;
	padding-bottom: 50px;
}
.product-row:last-child {
	border-bottom: 0.5px solid #000;
}
.product-name {
	width: 50%;
	font-family: NeueHaasDisplayBold, Arial, Helvetica, sans-serif;
	color: #1470af;
	font-size: 1.6em;
}
.product-desc,
.product-link {
	font-family: NeueHaasDisplayThinItalic, Arial, Helvetica, sans-serif;
	text-decoration: underline;
	color: #1470af;
}
.product-desc {
	width: 50%;
	font-size: 1.7em;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.product-link {
	padding-left: 5px;
}
@media (max-width: 768px) {
	.products-title {
		font-size: 4em;
		padding-left: 10vw;
	}
	.products-container {
		padding-left: 10vw;
		padding-right: 10vw;
	}
	.product-name,
	.product-row {
		width: 100%;
	}
	.product-desc {
		font-size: 1.3em;
		padding-top: 30px;
		width: 100%;
	}
}
@media (min-width: 769px) and (max-width: 1544px) {
	.products-title {
		font-size: 5em;
	}
	.product-row {
		width: 100%;
	}
	.product-desc {
		font-size: 1.3em;
	}
	.products-container {
		padding-top: 10vw;
		padding-bottom: 10vw;
	}
	footer {
		padding-bottom: 5vh;
	}
}
