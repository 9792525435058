#our-vision-text,
button {
	color: #fff;
}
body {
	margin: 0;
	padding: 0;
}
.blur-load {
	background-repeat: no-repeat;
	background-size: cover;
}
.welcome-banner {
	position: relative;
	width: 100%;
	height: 850px;
	overflow: hidden;
  }

  .noise-overlay {
	content: "";
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background-image: url("/src/static/noise.png");
	animation: 200ms infinite bg-animation;
	opacity: 0.9;
	filter: brightness(0.4);
	pointer-events: none;
  }

@keyframes bg-animation {
	0% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(-5%, -5%);
	}
	20%,
	50% {
		transform: translate(-10%, 5%);
	}
	30% {
		transform: translate(5%, -10%);
	}
	40% {
		transform: translate(-5%, 15%);
	}
	60% {
		transform: translate(15%, 0);
	}
	70% {
		transform: translate(0, 10%);
	}
	80% {
		transform: translate(-15%, 0);
	}
	90% {
		transform: translate(10%, 5%);
	}
	100% {
		transform: translate(5%, 0);
	}
}
.welcome-banner-text {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: 15vh;
	margin-left: 5vh;
	margin-right: 5vh;
	color: #fff;
	font-size: 3.5em;
	line-height: 130%;
}
#import-export-text,
.big-text {
	color: #1470af;
}
.center-text {
	padding: 7vw;
	display: flex;
	justify-content: center;
}
.center-text-content {
	max-width: 70%;
	letter-spacing: 0.02em;
	text-align: center;
	vertical-align: middle;
}
.center-text-title {
	font-family: NeueHaasDisplayBold, Arial, Helvetica, sans-serif;
	font-size: 3.2em;
}
.center-text-body {
	padding-top: 30px;
	font-family: NeueHaasDisplayThin, Arial, Helvetica, sans-serif;
	font-size: 1.8em;
	line-height: 1.5em;
}
.columns {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
#products-touch {
	background-color: #eaeaee;
}
.get-in-touch,
.our-products {
	padding: 7vw 7vw 7vw 10vw;
	width: 50%;
	text-align: left;
	vertical-align: middle;
}
.big-text {
	font-family: NeueHaasDisplayBold, Arial, Helvetica, sans-serif;
	font-size: 5em;
}
.list,
.thin-body {
	font-size: 1.4em;
	letter-spacing: 0.03em;
	line-height: 2em;
	font-family: NeueHaasDisplayLight, Arial, Helvetica, sans-serif;
}
.list {
	padding-left: 45px;
	padding-top: 20px;
}
.thin-body {
	padding-left: 2rem;
	padding-top: 30px;
	width: 85%;
}
button {
	font-family: NeueHaasDisplayLight, Arial, Helvetica, sans-serif;
	background-color: #1470af;
	border: none;
	padding: 15px 25px;
	margin-top: 30px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 1.1em;
	letter-spacing: 0.1em;
	border-radius: 4px;
	cursor: pointer;
}
@media (max-width: 768px) {
	.center-text,
	.get-in-touch {
		padding-right: 30px;
	}
	.welcome-banner {
		height: 70vh;
	}
	.welcome-banner-text {
		font-size: 2.2em;
		margin-top: 15vh;
		margin-left: 3vh;
	}
	.center-text {
		padding-top: 80px;
		padding-left: 30px;
	}
	.get-in-touch,
	.our-products {
		padding-left: 10vw;
		width: 100%;
	}
	.center-text-content {
		max-width: 100%;
	}
	.center-text-title {
		font-size: 2.2em;
	}
	.center-text-body {
		font-size: 1.4em;
	}
	.big-text {
		font-size: 3em;
	}
	.list {
		font-size: 1.2em;
		padding-left: 30px;
	}
	.thin-body {
		width: 100%;
		font-size: 1.1em;
		line-height: 2em;
		padding-left: 0;
	}
}
@media (min-width: 769px) and (max-width: 1544px) {
	.welcome-banner {
		height: 65vh;
	}
	.welcome-banner-text {
		font-size: 2.2em;
		margin-top: 15vh;
		margin-left: 3vh;
	}
	.center-text {
		padding-top: 80px;
		padding-left: 25vw;
		padding-right: 25vw;
	}
	.get-in-touch,
	.our-products {
		padding-left: 40px;
	}
	.center-text-content {
		max-width: 100%;
	}
	.center-text-title {
		font-size: 2.2em;
	}
	.center-text-body {
		font-size: 1.4em;
	}
	.big-text {
		font-size: 3em;
	}
	.get-in-touch {
		padding-right: 30px;
	}
	.list {
		font-size: 1.2em;
		padding-left: 30px;
	}
	.thin-body {
		width: 100%;
		font-size: 1.1em;
		line-height: 2em;
	}
}
